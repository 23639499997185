import { memo } from 'react';
import { useRouter } from 'next/router';
import { m } from 'framer-motion';

import Head from '@/components/Head/Head';

function NotFound() {
  const router = useRouter();

  return (
    <m.div
      className="Pages Pages--404"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.2 } }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <Head title={router.locale === 'en' ? 'Not Found | i.o.l.e.' : 'Non trovato | i.o.l.e.'} />

      <section className="notFoundContent" data-scroll-section>
        <h1 className="title">{router.locale === 'en' ? 'Sorry, nothing found' : 'Ops, nulla a questo indirizzo'}</h1>
      </section>

      <style jsx>{`
        @import '../../styles/shared';

        .notFoundContent {
          width: 100%;
          position: relative;
          @include flex-center;
          h1 {
            text-align: center;
            width: 60%;
            display: block;
            position: relative;
            @include clean;
            font-family: var(--font-secondary);
            color: $dark;
            font-weight: 650;
            line-height: 1.1;
            transform-origin: 0% 50%;
            will-change: transform;
            transform: translateY(150%);
            font-size: 3.2rem;
            font-size: clamp(2.2rem, 1.0072rem + 5.3012vw, 4.4rem);
            @include media('>=large') {
              letter-spacing: px(1);
              font-size: 5rem;
              font-size: clamp(4.1rem, 0.617rem + 5.4422vw, 8.1rem);
            }
          }
        }
      `}</style>
    </m.div>
  );
}

export default memo(NotFound);
